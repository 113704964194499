import React from 'react';
import './App.scss';

function App() {
    return (
    <div className="App">

        <div className={'container pt-2 pt-lg-5'}>

            <h1 className={'display-4'}>Kindale Yield Calculator</h1>

            <hr />

            <Calculator />
        </div>

    </div>
    );
}

class Calculator extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            purchase_price: '',
            rental_income: '',
            total_purchase_cost: 0,
            stamp_duty_payable: 0,
            stamp_duty_decimal: 0,
            stamp_duty_percentage: 0,
            legal_fees_percentage: 1.8,
            legal_fees_decimal: 1.8,
            total_fees_decimal_display: 0,
            yield_calculation: 0,
            total_fees_cost: 0,
            has_calculated: false
        };

        this.calculateYieldFromValue    = this.calculateYieldFromValue.bind(this);
        this.handlePurchasePriceChange  = this.handlePurchasePriceChange.bind(this);
        this.handleRentalIncomeChange   = this.handleRentalIncomeChange.bind(this);
        this.handleLegalFeesChange      = this.handleLegalFeesChange.bind(this);

    }

    /**
     * Calculates the yield from the input parameters.
     *
     * @param   e
     * @return  void
     */
    calculateYieldFromValue(e) {

        e.preventDefault();

        if (this.state.purchase_price.length === 0 || this.state.rental_income.length === 0) {
            return;
        }

        if (this.state.purchase_price <= 0 || this.state.rental_income <= 0) {
            return;
        }

        let has_calculated              = true;
        let stamp_duty_payable          = this.calculateStampDuty(this.state.purchase_price);
        let stamp_duty_decimal          = (stamp_duty_payable / this.state.purchase_price);
        let stamp_duty_percentage       = (stamp_duty_decimal * 100).toFixed(2);
        let legal_fees_decimal          = this.state.legal_fees_percentage / 100;
        let total_fees_decimal          = 1 + (stamp_duty_decimal + legal_fees_decimal);
        let total_fees_decimal_display  = ((stamp_duty_decimal + legal_fees_decimal) * 100).toFixed(2);
        let total_purchase_cost         = (this.state.purchase_price * total_fees_decimal).toFixed(0);
        let total_fees_cost             = total_purchase_cost - this.state.purchase_price;
        let yield_calculation           = ((this.state.rental_income / total_purchase_cost) * 100).toFixed(2);

        this.setState({
            has_calculated,
            stamp_duty_payable,
            stamp_duty_decimal,
            stamp_duty_percentage,
            legal_fees_decimal,
            total_fees_decimal_display,
            yield_calculation,
            total_fees_decimal,
            total_purchase_cost,
            total_fees_cost
        });

    }

    /**
     * Returns the stamp duty payable on the given number.
     *
     * @param purchase_price
     * @return {number}
     */
    calculateStampDuty(purchase_price) {

        let stamp_duty_payable = 0;

        if (purchase_price > 150000) {

            // IF(F1>=250000,2%*100000,(F1-150000)*2%)
            stamp_duty_payable += (purchase_price >= 250000) ? (100000 * 0.02) : ((purchase_price - 150000) * 0.02);

            // IF(F1>250000,5%*(F1-250000),0)
            if (purchase_price >= 250000) {

                stamp_duty_payable += ((purchase_price - 250000) * 0.05);

            }

        }

        return stamp_duty_payable;

    }

    handlePurchasePriceChange(event) {
        let value = event.target.value.length > 0 ? parseInt(event.target.value.substring(0, 12)) : '';
        if (value < 0) value = '';
        this.setState({purchase_price: value});
    }

    handleRentalIncomeChange(event) {
        let value = event.target.value.length > 0 ? parseInt(event.target.value.substring(0, 12)) : '';
        if (value < 0) value = '';
        this.setState({rental_income: value});
    }

    handleLegalFeesChange(event) {
        let value = event.target.value.length > 0 ? parseFloat(event.target.value.substring(0, 5)) : '';
        if (value < 0) value = '';
        this.setState({legal_fees_percentage: value});
    }

    render() {

        return (

            <div>

                <form onSubmit={this.calculateYieldFromValue}>

                    <div className={'row'}>

                        <div className={'col-md-4'}>
                            <div className={'form-group'}>
                                <label htmlFor={'rental_income'}>
                                    Rental Income
                                </label>
                                <input type={'number'} className={'form-control'} id={'rental_income'} name={'rental_income'}
                                value={this.state.rental_income} onChange={this.handleRentalIncomeChange} maxLength={12} required={true} />
                            </div>
                        </div>

                        <div className={'col-md-4'}>
                            <div className={'form-group'}>
                                <label htmlFor={'purchase_price'}>
                                    Purchase Price
                                </label>
                                <input type={'number'} className={'form-control'} id={'purchase_price'} name={'purchase_price'}
                                value={this.state.purchase_price} onChange={this.handlePurchasePriceChange} maxLength={12} required={true} />
                            </div>
                        </div>

                        <div className={'col-md-4'}>
                            <div className={'form-group'}>
                                <label htmlFor={'legal_fees_percentage'}>
                                    Legal &amp; Agents Fees
                                </label>
                                <input type={'number'} className={'form-control'} id={'legal_fees_percentage'} name={'legal_fees_percentage'}
                                value={this.state.legal_fees_percentage} onChange={this.handleLegalFeesChange} required={true} step={0.01} />
                            </div>
                        </div>

                    </div>

                    <div className={'row justify-content-end'}>

                        <div className={'col-md-4 text-right'}>
                            <button type={'submit'} className={'btn btn-primary btn-lg btn-block mb-3 d-md-none'}>Calculate</button>
                            <button type={'submit'} className={'btn btn-primary mb-3 d-none d-md-inline-block'}>Calculate</button>
                        </div>

                    </div>

                </form>

                {this.state.has_calculated &&
                    <div className={'row py-2'}>
                        <div className={'col-md-6 order-2 order-md-1'}>
                            <table className={'table mb-0'}>
                                <tbody>
                                    <tr>
                                        <td className={'w-50'}>
                                            <strong>Yield</strong>
                                        </td>
                                        <td>
                                            {this.state.yield_calculation}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Costs</strong>
                                        </td>
                                        <td>
                                            &pound;{Number(this.state.total_fees_cost).toLocaleString()} ({this.state.total_fees_decimal_display}%)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Purchase Price</strong>
                                        </td>
                                        <td>
                                            &pound;{Number(this.state.purchase_price).toLocaleString()}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Total Cost Inc. Fees</strong>
                                        </td>
                                        <td>
                                            &pound;{Number(this.state.total_purchase_cost).toLocaleString()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={'col-md-6 order-1 order-md-2'}>
                            <table className={'table mb-0'}>
                                <tbody>
                                    <tr>
                                        <td className={'w-50'}>
                                            <strong>Stamp Payable</strong>
                                        </td>
                                        <td>
                                            &pound;{this.state.stamp_duty_payable.toLocaleString()}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Legal &amp; agents fees</strong>
                                        </td>
                                        <td>
                                            {this.state.legal_fees_percentage}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Stamp duty</strong>
                                        </td>
                                        <td>
                                            {this.state.stamp_duty_percentage}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Total Costs %</strong>
                                        </td>
                                        <td>
                                            {this.state.total_fees_decimal_display}%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

            </div>

        );

    }

}

export default App;
